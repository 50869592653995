<template>
    <div class="cart-checkout-container">
        <BreadCrumbs />
        <section class="listSection listing">
            <div class="container">
                <div id="pages">
                    <div class="headline">
                        <h1>Kosár</h1>
                        <hr />
                    </div>
                    <CheckoutBreadcrumbs :step="1" />
                    <template v-for="(item, index) in cart.items">
                        <div
                            class="row listElement"
                            v-bind:key="'cart-' + index"
                        >
                            <div class="col-lg-3 listImage">
                                <img
                                    v-if="
                                        item.product.globalPattern &&
                                            item.product.globalPattern.image
                                    "
                                    :src="item.product.globalPattern.image"
                                />
                                <img v-else :src="item.product.kepnev" />
                            </div>
                            <div class="col-lg-3 flexevenly mainCol">
                                <div>
                                    <h3 class="item-name">
                                        {{ item.product.emarka }}
                                        {{ item.product.pattern }}
                                        {{ item.product.m1nev }}
                                    </h3>
                                    <span class="item-type"
                                        >{{ item.product.width }}/{{
                                            item.product.profile
                                        }}R{{ item.product.coll }}</span
                                    >
                                </div>
                                <div class="flexrow labels">
                                    <img
                                        src="/images/minoseg_eso.png"
                                    /><br />{{ item.product.fekezes }}
                                    <img
                                        src="/images/minoseg_fogyasztas.png"
                                    /><br />{{ item.product.fogyasztas }}
                                    <img
                                        src="/images/minoseg_zaj.png"
                                    /><br />{{ item.product.zaj }}
                                    <!--<span class="stock"
                                        >{{ item.product.tat }} db</span
                                    >-->
                                </div>
                                <div class="flexrow">
                                    <img
                                        class="katyugarancia"
                                        src="/images/raktaron.JPG"
                                        v-if="
                                            item.product.tat > 0 ||
                                                parseInt(
                                                    item.product.kulso_raktar
                                                ) > 0
                                        "
                                    />
                                    <a
                                        target="_blank"
                                        href="https://www.katyugarancia.hu/"
                                    >
                                        <img
                                            v-if="item.product.mo === 'VAN'"
                                            class="katyugarancia"
                                            src="/images/katyugarancia.png"
                                        />
                                    </a>
                                </div>
                            </div>
                            <!--<img class="akcio" src="images/akciobadge.JPG" />-->
                            <div class="col-lg-3 description verticalBottom">
                                <ul class="twoColumns first">
                                    <li>Defekttűrő</li>
                                    <li>Erősített oldalfal (XL)</li>
                                    <li>Súly index</li>
                                    <li>Sebesség index</li>
                                    <li>Szezon</li>
                                    <li>Gépjármű</li>
                                </ul>
                                <ul class="twoColumns bold">
                                    <li v-if="item.product.defektt">Igen</li>
                                    <li v-else>Nem</li>
                                    <li
                                        v-if="
                                            item.product.xlfr &&
                                                item.product.xlfr === 'XL'
                                        "
                                    >
                                        Igen
                                    </li>
                                    <li v-else>Nem</li>
                                    <li>
                                        {{ weightIndex(item.product.li) }}
                                    </li>
                                    <li>
                                        {{ item.product.si }}
                                        {{ speedIndex(item.product.si) }}
                                    </li>
                                    <li>{{ item.product.m1nev }}</li>
                                    <li>{{ getM2Nev(item.product.m2nev) }}</li>
                                </ul>
                            </div>
                            <div class="col-lg-3 actions">
                                <div class="prices">
                                    <span
                                        class="item-price sale"
                                        v-bind:class="{
                                            underlined:
                                                item.product.szemar !== 0
                                        }"
                                    >
                                        Ár:
                                        {{
                                            item.product
                                                .calculated_special_price
                                        }}
                                        / db
                                    </span>
                                    <template
                                        v-if="
                                            item.product.tat <= 6 &&
                                                item.product.tat !== 0
                                        "
                                        ><span class="lastOnes"
                                            >Utolsó
                                            {{ item.product.tat }} db!</span
                                        ></template
                                    >
                                </div>
                                <div>
                                    <input
                                        type="number"
                                        v-model="item.quantity"
                                        min="1"
                                        v-on:change="updateCart"
                                        v-on:keyup="updateCart"
                                        max="20"
                                        class="form-control"
                                    />
                                    <button
                                        class="button cart-delete"
                                        v-on:click="deleteCart(item.id)"
                                    >
                                        Törlés
                                        <img
                                            src="img/bin.svg"
                                            class="small-icon"
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <!-- pagination end -->
                <div class="sum flexrow justify-content-md-between">
                    <div class="container">
                        <div class="row my-4">
                            <div class="col-xl-9 mb-4 mb-xl-0">
                                <router-link
                                    :to="'/'"
                                    class="d-block continue-shop-btn transition"
                                    >Vásárlás folytatása</router-link
                                >
                            </div>
                            <div
                                class="col-xl-3"
                                v-if="cart && cart.cart_total"
                            >
                                <div class="row">
                                    <div class="col-6">
                                        Abroncs végösszeg:
                                    </div>
                                    <div class="col-6 text-end">
                                        {{ cart.cart_total.total_f }} Ft
                                    </div>
                                </div>
                                <router-link
                                    :tag="'button'"
                                    :to="{ name: 'CheckoutBilling' }"
                                    class="btn btn-primary w-100 my-4"
                                >
                                    Tovább a pénztárhoz
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="width:100%;padding:0; margin:0;" />
            </div>
        </section>
    </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import BreadCrumbs from "@/components/BreadCrumbs";
import CheckoutBreadcrumbs from "@/components/CheckoutBreadcrumbs";

export default {
    name: "Cart",
    components: { CheckoutBreadcrumbs, BreadCrumbs },
    data() {
        return {
            cart: {},
            form: {
                email: null,
                password: null
            },
            speed_table: {},
            weight_table: {}
        };
    },
    mounted() {
        if (document.getElementById("pages") !== null) {
            $("html, body").animate(
                {
                    scrollTop: $("#pages").offset().top - 150
                },
                1000
            );
        }
        this.speed_table = {
            A1: "5",
            A2: "10",
            A3: "15",
            A4: "20",
            A5: "25",
            A6: "30",
            A7: "35",
            A8: "40",
            B: "50",
            C: "60",
            D: "65",
            E: "70",
            F: "80",
            G: "90",
            J: "100",
            K: "110",
            L: "120",
            M: "130",
            N: "140",
            P: "150",
            Q: "160",
            R: "170",
            S: "180",
            T: "190",
            U: "200",
            H: "210",
            V: "240",
            ZR: "240 felett",
            W: "270",
            Y: "300",
            "(Y)": "300 felett"
        };
        this.weight_table = {
            "50": "190",
            "51": "195",
            "52": "200",
            "53": "206",
            "54": "212",
            "55": "218",
            "56": "224",
            "57": "230",
            "58": "236",
            "59": "243",
            "60": "250",
            "61": "257",
            "62": "265",
            "63": "272",
            "64": "280",
            "65": "290",
            "66": "300",
            "67": "307",
            "68": "315",
            "69": "325",
            "70": "335",
            "71": "345",
            "72": "355",
            "73": "365",
            "74": "375",
            "75": "387",
            "76": "400",
            "77": "412",
            "78": "425",
            "79": "437",
            "80": "450",
            "81": "462",
            "82": "475",
            "83": "487",
            "84": "500",
            "85": "515",
            "86": "530",
            "87": "545",
            "88": "560",
            "89": "580",
            "90": "600",
            "91": "615",
            "92": "630",
            "93": "650",
            "94": "670",
            "95": "690",
            "96": "710",
            "97": "730",
            "98": "750",
            "99": "775",
            "100": "800",
            "101": "825",
            "102": "850",
            "103": "875",
            "104": "900",
            "105": "925",
            "106": "950",
            "107": "975",
            "108": "1000",
            "109": "1030",
            "110": "1060",
            "111": "1090",
            "112": "1120",
            "113": "1150",
            "114": "1180",
            "115": "1215",
            "116": "1250",
            "117": "1285",
            "118": "1320",
            "119": "1360",
            "120": "1400",
            "121": "1450",
            "122": "1500",
            "123": "1550",
            "124": "1600",
            "125": "1650",
            "126": "1700",
            "127": "1750",
            "128": "1800",
            "129": "1850",
            "130": "1900",
            "131": "1950",
            "132": "2000",
            "133": "2060",
            "134": "2120",
            "135": "2180",
            "136": "2240",
            "137": "2300",
            "138": "2360",
            "139": "2430",
            "140": "2500",
            "141": "2575",
            "142": "2650",
            "143": "2725",
            "144": "2800",
            "145": "2900",
            "146": "3000",
            "147": "3075",
            "148": "3150",
            "149": "3250",
            "150": "3350",
            "151": "3450",
            "152": "3550",
            "153": "3650",
            "154": "3750",
            "155": "3875",
            "156": "4000",
            "157": "4125",
            "158": "4250",
            "159": "4375",
            "160": "4500",
            "161": "4625",
            "162": "4750",
            "163": "4875",
            "164": "5000",
            "165": "5150",
            "166": "5300",
            "167": "5450",
            "168": "5600",
            "169": "5800"
        };
    },
    computed: {
        url: function() {
            return process.env.VUE_APP_API_DIOS_URL;
        },
        loggedIn: function() {
            if ($cookies.get("id_token") && $cookies.get("user")) {
                return true;
            }
            return false;
        }
    },
    beforeMount() {
        this.getCart();
    },
    methods: {
        getM2Nev(type) {
            switch (type) {
                case "SZGK.":
                    return "Személygépkocsi";
                case "4X4":
                    return "4X4,SUV";
                case "TEHER":
                    return "Kisteherautó";
                default:
                    return type;
            }
        },
        doLogin() {
            this.$http.post(`${this.url}/login`, this.form).then(response => {
                $cookies.set("id_token", response.data.token, 86400);
                $cookies.set("user", response.data, 86400);

                document.location.href = "/checkout/billing";
            });
        },
        deleteCart(id) {
            const self = this;
            const cart_uuid = $cookies.get("cart_uuid");
            ApiService.delete(
                `${self.url}/cart?uuid=${cart_uuid}&id=${id}`
            ).finally(() => {
                self.getCart();
                self.$eventHub.$emit("updateCart", true);
            });
        },
        updateCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.post(`${this.url}/cart`, {
                cart: this.cart,
                uuid: cartUuid
            }).then(response => {
                $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                this.$eventHub.$emit("updateCart", true);
                this.cart = response.data.data;
            });
        },
        getCart() {
            const cartUuid = $cookies.get("cart_uuid");
            ApiService.query(`${this.url}/cart?uuid=${cartUuid}`).then(
                response => {
                    $cookies.set("cart_uuid", response.data.data.uuid, 864000);
                    this.cart = response.data.data;
                }
            );
        },
        weightIndex(indexinput) {
            if (this.weight_table[indexinput]) {
                return "(" + this.weight_table[indexinput] + " kg)";
            }
            if (indexinput.includes("/")) {
                indexinput = indexinput.replaceAll(" ", "");
                let weight_index_array = indexinput.split("/");
                let returnstring = String(indexinput) + " = ";
                for (let i = 0; i < weight_index_array.length; i++) {
                    returnstring =
                        returnstring +
                        this.weight_table[weight_index_array[i]] +
                        " kg";
                    if (i === 0) {
                        returnstring = returnstring + "/";
                    }
                }
                return returnstring;
            }
        },

        speedIndex(indexinput) {
            if (this.speed_table[indexinput]) {
                return "(" + this.speed_table[indexinput] + " km/h)";
            }
        }
    }
};
</script>

<style scoped></style>
